
 <div style="padding-top: 0px" data-spy="scroll" data-target=".site-navbar-target" data-offset="300">


    <div class="site-wrap" id="home-section">


    <div class="site-section">
        <div class="container">
          
          <div class="row center">
            <div class="col-lg-3 ml-auto">
                
            </div>
            <div class="col-lg-6 mb-5" >
              <form  autocomplete="off" *ngIf='!registerFormShowYN'>
                    <h3 class="text-cursive mb-4">Đăng nhập</h3>
                
                    <div class="row mb-5">
                    <div class="col-md-6 mr-auto">
                      <button type='button' (click)='socialSignIn("facebook")' class="btn btn-block btn-info text-white py-3 px-5 rounded-0 btn-block" >
                          Facebook
                      </button>
                    </div>
                    <div class="col-md-6 mr-auto">
                      <button   type='button' (click)='socialSignIn("google")'  class="btn btn-block btn-danger text-white py-3 px-5 rounded-0 btn-block" >
                          Google
                      </button>
                    </div>
                    </div>
                    <div class="form-group  row">
                    <div class="col-md-12">
                      <input type="text" class="form-control" placeholder="username"  [(ngModel)]="userid" [ngModelOptions]="{standalone: true}">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <input type="text" class="form-control" placeholder="paswword"   [(ngModel)]="pass" [ngModelOptions]="{standalone: true}">
                    </div>
                  </div>
  
                  <div class="form-group row">
                    <div class="col-md-12 mr-auto">
                      <input type="button" (click)="login()" class="btn btn-block btn-primary text-white py-2 px-3 rounded-0" value="Đăng nhập">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6 mr-auto">
                      <input type="button" (click)="registerFormShowYN = !registerFormShowYN" 
                          class="btn btn-block btn-secondary text-white py-2 px-3 rounded-0" value="Đăng ký">
                    </div>
                    <div class="col-md-6 mr-auto">
                      <input type="button" (click)="router.navigate(['/forgotpass']);" 
                          class="btn btn-block btn-secondary text-white py-2 px-3 rounded-0" value="quên mật khẩu">
                    </div>
                  </div>
              </form>

              <form  *ngIf='registerFormShowYN'>
                <h3 class="text-cursive mb-4">Đăng ký tài khoản</h3>
            
                <div class="form-group  row">
                <div class="col-md-12">
                  <input type="text" class="form-control" placeholder="username" 
                   [(ngModel)]="register.userid" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <input type="text" class="form-control" placeholder=""   
                  [(ngModel)]="register.pass" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <input type="text" class="form-control" placeholder=""   
                  [(ngModel)]="register.confirmpass" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <input type="text" class="form-control" placeholder=""   
                  [(ngModel)]="register.name" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <input type="text" class="form-control" placeholder="Email"   
                  [(ngModel)]="register.email" [ngModelOptions]="{standalone: true}">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12 mr-auto">
                  <input type="button" (click)="createAccount()" class="btn btn-block btn-primary text-white py-2 px-3 rounded-0" 
                    value="Đăng ký">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12 mr-auto">
                  <input type="button" (click)="registerFormShowYN = !registerFormShowYN" 
                  class="btn btn-block btn-secondary text-white py-2 px-3 rounded-0" 
                    value="Login">
                </div>
              </div>
          </form>
            </div>
            <div class="col-lg-3 ml-auto">
              
            </div>
          </div>
        </div>
      </div>
      </div>
  
    </div>   

  
      