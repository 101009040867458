import { Component, OnInit, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';


import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angular-6-social-login';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public userid: string;
  public pass: string;
  public loginEmit = new EventEmitter();
  public registerFormShowYN: boolean;
  public register: RegistObject;

  constructor(
    private socialAuthService: AuthService,
    public router: Router,
    private authService: AuthService,
    private http: HttpClient) {
    this.userid = 'vietnguyen3107';
    this.registerFormShowYN = false;
    this.register = {
      userid: '',
      pass: '',
      confirmpass: '',
      name: '',
      email: ''
    };
  }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      // this.router.navigate(['/']);
    }
  }
  public login() {
    if (true) {

      const headers = { Authorization: 'Bearer my-token', 'My-Custom-Header': 'foobar' };
      this.http.post<any>(environment.url + + '/login', { userid: this.userid, pass: this.pass }).subscribe(data => {

        if (data.success) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('user', JSON.stringify(data.user));
          this.router.navigate(['/events']);
          this.loginEmit.emit();
          // console.log(JSON.parse(localStorage.getItem('user')).id);

        } else {
          localStorage.clear();
          alert('username or password incorrect!');
        }
      });
    } else {
      alert('Please fill in ussername and password');
    }
  }

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    let loginLink = environment.url + + '/flogin';
    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
      loginLink = environment.url + + '/flogin';
    } else if (socialPlatform === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
      loginLink = environment.url + + '/glogin';
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        console.log(socialPlatform + ' sign in data : ', userData);

        // sign in with userdata
        this.http.post<any>(loginLink, userData).subscribe(data => {

          if (data.success) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('user', JSON.stringify(data.user));
            this.router.navigate(['/events']);
            this.loginEmit.emit();
            // console.log(JSON.parse(localStorage.getItem('user')).id);

          } else {
            localStorage.clear();
            alert('username or password incorrect!');
          }
        });
      }
    );
  }

  public createAccount() {
    if (true) {

      const headers = { Authorization: 'Bearer my-token', 'My-Custom-Header': 'foobar' };
      this.http.post<any>(environment.url + + '/register', this.register).subscribe(data => {

        if (data.success) {
          alert(data.message);
          this.registerFormShowYN = false;

        } else {
          alert(data.message);
        }
      });
    } else {
      alert('Please fill in ussername and password');
    }
  }
}
interface RegistObject {
  userid: string;
  pass: string;
  confirmpass: string;
  name: string;
  email: string;
}
